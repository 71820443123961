<template>
  <div>
    <van-list
      v-model="pointslistloading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :immediate-check="false"
      offset="0"
    >
      <!-- <div v-if="points"> -->
      <div class="van-clearfix pointlist">
        <div
          class="float-item"
          v-for="(point, index) in points.data"
          :key="point.id"
        >
          <Point
            :point="point"
            :cate_level_kemu="point.cate_level_kemu"
            :cate_level_keshu="point.cate_level_keshu"
            :firstPoint="index"
            :ctype="ctype"
          ></Point>
        </div>
      </div>
      <!-- </div> -->

      <!-- <van-loading v-else size="24px" vertical>加载中...</van-loading> -->
    </van-list>
  </div>
</template>

<script>

import Point from '@/components/course/Point'

export default ({
  components: {
    Point
  },
  props: ['classid', 'courseid', 'ctype'],
  methods: {
    getNowpage (page) {
      return page
    },
    getNextpage (page) {
      return page + 1
    },
    onLoad () {
      if (this.pointslistlength >= this.points.total) {
        this.finished = true
        this.$store.commit('pointslistloading', false)
        this.isLoadingFished = false
        return
      }

      this.NowPage = this.getNextpage(this.points.current_page)
      var getModel = 'ONLY'
      if (this.uid) {
        getModel = 'ALL'
      }
      //   this.classid = localStorage.getItem('classid')
      console.log(this.classid)
      this.$store.dispatch('getPointslitAction', {
        uid: this.uid,
        StudyCourseId: this.StudyCourseId,
        getModel: getModel,
        ZyPageSize: this.ZyPageSize,
        NowPage: this.NowPage,
        classid: this.classid
      })
      // 加载状态结束
      this.$store.commit('pointslistloading', false)
      console.log(this.pointslistlength, 'onLoad ok')
    }
  },
  data () {
    return {
      finished: false,
      isLoadingFished: true,
      getData: false,
      StudyCourseId: ''
    }
  },
  computed: {
    points () {
      return this.$store.state.study.pointslist
    },
    pointslistlength () {
      return this.$store.state.study.pointslist.data.length
    },
    pointslistloading: {
      get: function () {
        return this.$store.state.study.pointslistloading
      },
      set: function () {
        this.$store.commit('pointslistloading', false)
      }
    },
    pointslist () {
      let points = this.$store.state.study.pointslist
      if (points.length > 0) {
        return points
      } else {
        points = localStorage.getItem('pointslist')
      }
      var pointslistArr = []
      if (points !== null) {
        pointslistArr = JSON.parse(points)
      }
      return pointslistArr
      //   return TestInfoArr
    }
  },
  created () {
    // 第一次启动 为 undefined
    // 第二次启为  为  object
    this.StudyCourseId = Number(localStorage.getItem('StudyCourseId'))

    console.log(this.courseid, 'this.courseid')
    console.log(this.StudyCourseId, 'localStorage.StudyCourseId')
    console.log(this.points.courseid, 'points.cid')
    console.log(' ----  ')
    console.log(this.points, 'points')
    // console.log(typeof this.points, 'points')
    console.log(typeof this.points.courseid, 'typeof points.courseid')
    console.log(typeof this.StudyCourseId, 'typeof this.StudyCourseId')
    console.log(typeof this.points, 'typeof this.points')

    this.getData = false
    if (this.points === undefined) {
      this.getData = true
    } else if (typeof this.points === 'object') {
      if (this.points.length === 0) {
        this.getData = true
      }
      if (this.points.courseid !== this.StudyCourseId) {
        // // console.log(typeof this.courseid, 'this.courseiddddd')
        // // console.log(typeof this.points.courseid, 'this.courseiddddd')
        // vue.runtime.esm.js?2b0e:619 [Vue warn]: Error in created hook:
        // "TypeError: undefined is not iterable (cannot read property Symbol(Symbol.iterator))"
        // var pointtemp = {}
        this.$store.commit('unsetPointslist', '')
        // this.$store.commit('hideShowTabbar', true)
        localStorage.setItem('pointslist', '')
        console.log('清除列表')
        this.getData = true
      }
    }
    console.log(this.getData, 'getDataDDD')
    if (this.getData) {
      this.NowPage = this.getNowpage(1)
      // console.log(this.NowPage, 'nowpage')
      // console.log(this.points, 'points')
      this.uid = localStorage.getItem('uid')
      this.ZyPageSize = localStorage.getItem('ZyPageSize')
      var getModel = 'ONLY'
      if (this.uid) {
        getModel = 'ALL'
      }

      this.classid = localStorage.getItem('classid')
      // if (this.$store.state.study.course === null) {
      this.$store.dispatch('getPointslitAction', {
        uid: this.uid,
        StudyCourseId: this.StudyCourseId,
        getModel: getModel,
        ZyPageSize: this.ZyPageSize,
        NowPage: this.NowPage,
        classid: this.classid
      })
    }
  }

})
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
}
.pointlist {
  height: 100%;
  min-height: 27.778vw;
}
</style>
