var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title",on:{"click":function($event){return _vm.toPointDetail(_vm.point.id, _vm.point.lock, _vm.firstPoint)}}},[_c('b',[_vm._v(_vm._s(_vm.cate_level_kemu)+_vm._s(_vm.cate_level_keshu)+":"+_vm._s(_vm.point.pname))]),(_vm.point.lock && _vm.firstPoint > 0)?_c('img',{attrs:{"src":require("@/assets/lock.png"),"width":"12px","height":"12px"}}):_c('img',{attrs:{"src":require("@/assets/unlock.png"),"width":"16px","height":"12px"}})]),_c('van-row',[_c('van-col',{attrs:{"span":"8"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.point.pic),expression:"point.pic"}],attrs:{"width":"100%"},on:{"click":function($event){return _vm.toPointDetail(
            _vm.point.id,
            _vm.point.lock,
            _vm.ctype,
            _vm.cate_level_kemu,
            _vm.cate_level_keshu,
            _vm.firstPoint
          )}}})]),_c('van-col',{attrs:{"span":"16"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.point.xueshi)+" 学时 / "+_vm._s(_vm.point.views)+"人次")]),_c('div',{staticClass:"url"},[_vm._v(" 学习期限："+_vm._s(_vm.point.starttime)+" ~ "+_vm._s(_vm.point.stoptime)+" ")]),(_vm.ctype == 1)?_c('div',[_c('div',{staticClass:"url"},[_vm._v(" 测试 "+_vm._s(_vm.point.testtimes)+" 次 / "+_vm._s(_vm.point.done)+" (平均 "+_vm._s(_vm.point.avg)+" 分) ")]),_c('div',{staticClass:"btn"},[_c('span',{on:{"click":function($event){return _vm.toPointDetail(
                _vm.point.id,
                _vm.point.lock,
                _vm.ctype,
                _vm.cate_level_kemu,
                _vm.cate_level_keshu,
                _vm.firstPoint
              )}}},[_vm._v("查看文本")]),_vm._v(" / "),_c('span',{on:{"click":function($event){return _vm.toTest(_vm.point.id, _vm.point.pname, _vm.point.lock, _vm.firstPoint)}}},[_vm._v("学习")]),_vm._v(" / "),_c('span',{on:{"click":function($event){return _vm.toTestinfo(_vm.point.id, _vm.point.pname, _vm.point.lock, _vm.firstPoint)}}},[_vm._v("测试")])])]):_vm._e(),(_vm.ctype == 2)?_c('div'):_vm._e(),(_vm.ctype == 3)?_c('div',[_c('div',{staticClass:"url"},[_vm._v("共 "+_vm._s(_vm.point.zynum)+" 个视频")]),_c('div',{staticClass:"btn"},[_c('span',{on:{"click":function($event){return _vm.toPointDetail(
                _vm.point.id,
                _vm.point.lock,
                _vm.ctype,
                _vm.cate_level_kemu,
                _vm.cate_level_keshu,
                _vm.firstPoint
              )}}},[_vm._v("观看视频")])])]):_vm._e(),(_vm.ctype == 4)?_c('div'):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }