<template>
  <div class="course-detail">
    <img :src="course.pic" width="100%" />
    <div class="course-detail title">
      <b>{{ course.catname }} - {{ course.cname }}</b
      >({{ course.kcode }})
    </div>
    <div
      class="course-detail info"
      v-if="course.ctype == 1 || course.ctype == 4"
    >
      共 {{ course.xueshi }} 学时 / 已完成 {{ course.done }} 学时
    </div>
    <div class="course-detail info">
      {{ course.name }} / {{ course.views }}人次
    </div>
    <div class="course-detail info">课程简介</div>
    <div class="course-detail content">{{ course.content }}</div>
    <div
      class="course-detail info"
      v-if="course.ctype == 1 || course.ctype == 4"
    >
      至少测试 5 次，平均 70 分以上，自动解锁下一集
    </div>
    <div class="course-detail info" v-else></div>
  </div>
</template>

<script>
export default ({
  props: ['classid', 'course']
})
</script>

<style lang="scss" scoped>
.course-detail {
  background-color: #eee;
  font-size: 3.889vw;
  .title {
    padding-left: 2.778vw;
  }
  .content {
    font-size: 3.611vw;
    line-height: 5.556vw;
    padding: 1.389vw;
  }
  .info {
    color: gray;
    padding-left: 2.778vw;
    font-size: 3.611vw;
  }
}
</style>
