<template>
  <div>
    <Courseinfo :course="course" :classid="classid"> </Courseinfo>
    <Pointslist
      :courseid="course.id"
      :classid="classid"
      :ctype="course.ctype"
    ></Pointslist>
  </div>
</template>

<script>
import Courseinfo from '@/components/course/Courseinfo'
import Pointslist from '@/components/course/Pointslist'
export default ({
  components: {
    Courseinfo, Pointslist
  },
  props: ['classid', 'course']

})
</script>
