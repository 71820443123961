<template>
  <div>
    <div v-if="course">
      <NavbarCommon
        :title="this.$store.state.study.course.cname"
        :back="back"
      ></NavbarCommon>
      <Course :course="course" :classid="this.classid"></Course>
    </div>
    <van-loading v-else size="24px" vertical>课程加载中...</van-loading>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import NavbarCommon from '@/components/common/NavbarCommon'
import Course from '@/components/course/Course'
export default {
  data () {
    return {
      //   courseup: false,
      coursecc: '',
      xueshi: 50,
      views: 5200,
      done: 50,
      back: 'buddhism',
      getData: false,
      show: ''
    }
  },
  components: {
    NavbarCommon, Course
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  mounted () {
    var courseid = this.$route.params.id
    console.log(courseid, 'courseid')
    // if (!this.user.age) {
    console.log(typeof this.$store.state.study.course)
    // if (this.$store.state.study.course === undefined) {
    //   //  getCourseDetailMutation (state, course) {
    //   //       state.study.course = JSON.parse(JSON.stringify(course))
    //   //     },
    //   this.$store.commit('getCourseDetailMutation', {})
    // }
    // classid
    console.log(localStorage.getItem('StudyCourseId'), 'StudyCourseId')
    if (this.$store.state.study.course === undefined) {
      this.getData = true
    } else if (typeof this.$store.state.study.course === 'object') {
      if (this.$store.state.study.course.length === 0) {
        this.getData = true
      }
      if (this.$store.state.study.course.id !== courseid) {
        this.getData = true
      }
    }
    console.log(this.getData, 'this.getData')
    if (this.getData) {
      this.uid = localStorage.getItem('uid')
      this.classid = localStorage.getItem('classid')
      this.StudyCourseId = localStorage.getItem('StudyCourseId')
      this.$store.dispatch('getCourseDetailAction', {
        uid: this.uid,
        StudyCourseId: this.StudyCourseId,
        classid: this.classid
      })
      console.log('请求课程')
    }
    console.log(this.$store.state.study.course, 'this.$store.state.study.course')
  },
  watch: {
    course () {
      console.log(this.$store.state.study.course, '.course')
      //   this.courseup = true
      this.show = true
      this.$forceUpdate()
    }
  },
  computed: {
    // ...mapState(['isShowTabBar']),
    classid: {
      get () {
        return localStorage.getItem('classid')
      },
      set () { }
    },
    course: {
      get () {
        if (this.$store.state.study.course === undefined) {
          return false
        } else if (typeof this.$store.state.study.course === 'object') {
          if (this.$store.state.study.course.length === 0) { return false }
        }
        return this.$store.state.study.course
      },
      set () { }
    }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }
}
</script>
