<template>
  <div>
    <div class="title" @click="toPointDetail(point.id, point.lock, firstPoint)">
      <b>{{ cate_level_kemu }}{{ cate_level_keshu }}:{{ point.pname }}</b>
      <img
        v-if="point.lock && firstPoint > 0"
        src="@/assets/lock.png"
        width="12px"
        height="12px"
      /><img v-else src="@/assets/unlock.png" width="16px" height="12px" />
    </div>
    <van-row>
      <van-col span="8">
        <img
          v-lazy="point.pic"
          width="100%"
          @click="
            toPointDetail(
              point.id,
              point.lock,
              ctype,
              cate_level_kemu,
              cate_level_keshu,
              firstPoint
            )
          "
        />
      </van-col>
      <van-col span="16">
        <div class="info">{{ point.xueshi }} 学时 / {{ point.views }}人次</div>
        <!-- <div class="url" @click="toVideo(point.url)">点击观看视频</div> -->
        <div class="url">
          学习期限：{{ point.starttime }} ~ {{ point.stoptime }}
        </div>
        <div v-if="ctype == 1">
          <div class="url">
            测试 {{ point.testtimes }} 次 / {{ point.done }} (平均
            {{ point.avg }} 分)
          </div>
          <div class="btn">
            <span
              @click="
                toPointDetail(
                  point.id,
                  point.lock,
                  ctype,
                  cate_level_kemu,
                  cate_level_keshu,
                  firstPoint
                )
              "
              >查看文本</span
            >
            /
            <span @click="toTest(point.id, point.pname, point.lock, firstPoint)"
              >学习</span
            >
            /
            <span
              @click="toTestinfo(point.id, point.pname, point.lock, firstPoint)"
              >测试</span
            >
          </div>
        </div>
        <div v-if="ctype == 2"></div>
        <div v-if="ctype == 3">
          <div class="url">共 {{ point.zynum }} 个视频</div>
          <div class="btn">
            <span
              @click="
                toPointDetail(
                  point.id,
                  point.lock,
                  ctype,
                  cate_level_kemu,
                  cate_level_keshu,
                  firstPoint
                )
              "
              >观看视频</span
            >
          </div>
        </div>
        <div v-if="ctype == 4"></div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  components: {
    // Point1, Point2, Point3, Point4
  },
  props: ['point', 'ctype', 'firstPoint', 'cate_level_kemu', 'cate_level_keshu'],
  methods: {
    toTest (id, pname, lock, firstPoint) {
      if (lock && firstPoint > 0) {
        this.$msg('未解锁...')
        return
      }
      localStorage.setItem('TestPointid', id)
      localStorage.setItem('Testpname', pname)
      this.$router.push('/test')
    },
    toPointDetail (id, lock, ctype, kemu, keshu, firstPoint) {
      if (lock && firstPoint > 0) {
        this.$msg('未解锁...')
        return
      }

      localStorage.setItem('StudyPointid', id)
      this.$router.push({
        name: 'Pointinfo',
        params: {
          id: id,
          lock: lock,
          ctype: ctype,
          kemu: kemu,
          keshu: keshu,
          firstPoint: firstPoint
        }
      })
    },
    // toVideo (url, lock, firstPoint) {
    //   if (lock && firstPoint > 0) {
    //     msg.$msg('未解锁...')
    //     return
    //   }
    //   localStorage.setItem('PointVideoUrl', url)
    //   this.$router.push('/pointvideo')
    // },
    toTestinfo (id, pname, lock, firstPoint) {
      if (lock && firstPoint > 0) {
        this.$msg('未解锁...')
        return
      }
      localStorage.setItem('TestPointid', id)
      localStorage.setItem('Testpname', pname)
      this.$router.push('/Testinfo')
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 3.333vw;
  padding-top: 0.833vw;
  padding-bottom: 0.556vw;
  color: midnightblue;
  padding-left: 1.389vw;
}
.info {
  color: #666;
  font-size: 3.333vw;
  padding-left: 1.389vw;
}
.url {
  color: #888;
  font-size: 3.333vw;
  padding-top: 0.833vw;
  padding-bottom: 0.556vw;
  padding-left: 1.389vw;
}
.btn {
  font-size: 3.333vw;
  padding-left: 1.389vw;
  padding-top: 0.833vw;
  color: red;
}
img {
  padding-left: 0.556vw;
}
</style>
